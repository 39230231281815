import { withFormik } from 'formik';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { LanguageContext } from '../../context/LanguageContext';
import { formHandler } from './handle-form';
import { ButtonSubmit } from './styles';
function Form({
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  isSubmitting,
}) {
  const { idioma } = useContext(LanguageContext);

  var nome = ['Nome completo', 'Full name', 'Nombre completo'];
  var email = ['E-mail', 'Email', 'Correo electrónico'];
  var cell = ['Celular', 'Cell phone', 'Celular'];
  var message = ['Mensagem', 'Message', 'Mensaje'];
  var play = [
    'Dê um play nessa parceria',
    'Press play for this partnership',
    'Dar Play en esta asociación',
  ];
  var contact = ['Entre em contato conosco', 'Contact us', 'Contáctenos'];

  return (
    /*<FormContainer onSubmit={handleSubmit}>
      <div>
        <FormGroup required>
          <label htmlFor="name">{nome[Idioma()]}</label>
          <CustomInput
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            id="name"
            name="name"
            error={errors.name && touched.name}
          />
          <ErrorMessage
            name="name"
            render={msg => <span className="error">{msg}</span>}
          />
        </FormGroup>
      </div>
      <div>
        <FormGroup required>
          <label htmlFor="mail">{email[Idioma()]}</label>
          <CustomInput
            value={values.mail}
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            id="mail"
            name="mail"
            error={errors.mail && touched.mail}
          />
          <ErrorMessage
            name="mail"
            render={msg => <span className="error">{msg}</span>}
          />
        </FormGroup>
        <FormGroup required>
          <label htmlFor="phone">{cell[Idioma()]}</label>
          <CustomInput
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            name="phone"
            mask={[
              "(",
              /[1-9]/,
              /[1-9]/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            error={errors.phone && touched.phone}
          />

          <ErrorMessage
            name="phone"
            render={msg => <span className="error">{msg}</span>}
          />
        </FormGroup>
      </div>
      <FormGroup required>
        <label htmlFor="message">{message[Idioma()]}</label>
        <CustomInput
          value={values.message}
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          id="message"
          name="message"
          error={errors.message && touched.message}
        />
        <ErrorMessage
          name="message"
          render={msg => <span className="error">{msg}</span>}
        />
      </FormGroup>
      <ButtonSubmit type="submit">
        <AiOutlinePlayCircle size={48} /> {play[Idioma()]}
      </ButtonSubmit>
      {isSubmitting && (
        <Loading>
          <PulseLoader size={30} loading={isSubmitting} color="#002a6a" />
        </Loading>
      )}
    </FormContainer>*/
    <Link
      to="https://produtos.hubfintech.com.br/contato"
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      <ButtonSubmit>
        <AiOutlinePlayCircle size={48} />
        {contact[idioma]}
      </ButtonSubmit>
    </Link>
  );
}

export default withFormik(formHandler)(Form);
