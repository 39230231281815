import styled from "styled-components"
import media from "styled-media-query"
import { darken } from "polished"
import InputMask from "react-input-mask"

export const FormContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  padding: 2rem 0;
  align-items: center;
  width: 100%;
  position: relative;
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    & > div + div {
      ${media.greaterThan("900px")`
        margin-left: 50px;
      `}
    }
  }

  ${media.lessThan("900px")`
    & > div {
      flex-direction: column;
    }
  `}
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  flex: 1;
  width: 100%;
  cursor: pointer;
  min-height: 110px;

  & label {
    font-size: 1.6rem;
    line-height: 2;
    text-align: left;
    color: #736f7e;
    width: 100%;

    position: relative;

    & ::after {
      content: "*";
      position: absolute;
      padding-left: 5px;
      display: ${props => (props.required ? "initial" : "none")};
    }
  }

  & .error {
    color: #d60000;
    font-size: 1.6rem;
    text-align: left;
    width: 100%;
    line-height: 2;
  }
`
export const CustomInput = styled(InputMask).attrs(props => ({
  mask: props.mask || false,
  error: null,
}))`
  width: 100%;
  border-bottom: solid 1px;
  outline: none;
  font-size: 1.8rem;
  text-align: left;
  color: #5c5c60;
  height: 26px;
  padding: 22px 0;
  transition: 0.5s ease-out;
  border-bottom-color: ${props => (props.error ? "#d60000" : "#b2b2b2")};
  max-length: 10;

  &:focus {
    padding-bottom: 21px;
    border-bottom: solid 2px;
    border-bottom-color: ${props => (props.error ? "#d60000" : "#00b1b1")};
  }
`
export const ButtonSubmit = styled.button`
  //margin: 7.5rem 0 2rem;
  width: fit-content;
  padding: 0.5rem 3rem 0.5rem 1.5rem;
  border-radius: 200px;
  background-color: #002a6a;
  color: #ffffff!important;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 15px;
  }
  :active,
  :hover {
    background: ${darken(0.1, "#002a6a")};
    text-decoration: none;
  }
  ${media.lessThan("600px")`
    margin: 4rem auto;
  `}
`

export const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 125px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end !important;
`
