import * as Yup from "yup"
import Axios from "axios"
import Idioma from '../Language/language'

var requiredField = ["Campo obrigatório", "Required field", "Campo obligatorio"]
var minimum = ["Este campo deve ter no mínimo 3 caracteres",
               "This field must contain at least 3 characters",
               "Este campo debe contener al menos 3 caracteres."]
var invalidEmail = ["E-mail inválido", "Invalid email", "Correo inválido"]

export const formHandler = {
  mapPropsToValues: () => ({
    name: "",
    mail: "",
    phone: "",
    message: "",
  }),
  validationSchema: () =>
    Yup.object().shape({
      name: Yup.string()
        .required(requiredField[Idioma()])
        .min(3, minimum[Idioma()]),
      mail: Yup.string()
        .email(invalidEmail[Idioma()])
        .required(requiredField[Idioma()]),
      phone: Yup.string().required(requiredField[Idioma()]),
      message: Yup.string().required(requiredField[Idioma()]),
    }),
  handleSubmit: async (values, { resetForm }) => {
    resetForm();
    try {

      await Axios.post(
        "http://ec2-54-89-23-122.compute-1.amazonaws.com:8082/send",
        {
          personalizations: [
            {
              to: [{ email: "comercialhubfintech@hubfintech.com.br" }]
            }
          ],
          from: { email: "test@example.com" },
          subject: "Novo contato via hubfintech.com.br",
          content: [
            {
              type: "text/plain",
              value: JSON.stringify(values)
            }
          ],
        },
        {
          headers: {
            authorization: `Bearer SG._ - QOchnTTTi - U - Jq - sUbIg.Y04ZtUW4I8GqvsyXluUUizjIG3WlTQfULS5Pi21RJB0`
          }
        }
      );

        /*method: 'post',
        url: 'http://mailer.hubprepaid.com.br/mails/hubprepaid/mp',
        data: {
          SenderName: 'HubPrepaid',
          From: 'test@example.com',
          To: 'renan.souza@hubfintech.com.br',
          Subject: 'Novo contato via hubfintech.com.br',
          Body: JSON.stringify(values),
        }*/

    } catch (error) {
      console.log(error.message);

    }
  },
}
